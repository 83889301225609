<script>
import $ from "jquery"
import _ from "lodash"
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

export default {
	extends: GlobalVue,
  props: {
    type: String,
    value: [String, Array],
    label: String,
    uploadType: {default: ""},
    attr:{default:()=>({})},
    param: {default:()=>({thumbnail:false})},
    galleryParams: {default:()=>({})},
    config: Object,
    noHelp: Boolean,
    noLoading: {type:Boolean,default:()=>(true)},
    noPreview: Boolean,
    block: Boolean,
    btnClass: String, 
    readonly: Boolean,
    isMultiple: Boolean, 
    usePreview: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      uploading: false,
      error: false,
      inputFile: null,
      imgFile: String,
      imgFileMultiple: [],
      fullPreviewOpen: false
    }
  },
  computed:{
    fields(){ return this.$root.fields },
    conf(){ return _.assign(_.clone(this.$root.app.uploader[this.type]),this.config||{}) },
    base(){ return this.$root.apiUrl },
    accept(){
      return "."+this.conf.rule_type.split(",").join(",.")
    },
    uploadText(){
      if(this.imgFileMultiple.length > 0 && this.isMultiple) return "Add More"
      if(this.value && !this.isMultiple) return "Update"
      return this.uploadType == "gallery" ? "Select" : "Upload"
    }
  },
  mounted(){
    this.init()
    this.inputFile = $("<input type='file' accept='"+this.accept+"'>")[0]
    this.inputFile.onclick = ()=>{ this.inputFile.value = '' }
    this.inputFile.onchange = this.onUpload
    global.inputFile = this.inputFile
    
    if(this.$slots.btn) this.$slots.btn[0].elm.onclick = this.uploadClick
  },
  methods: {
    init(){
      if(!this.conf) return
      this.param.type = this.type
      if (!this.uploadType){
        this.param.uploadType = this.conf.img_ratio ? "cropping" : "upload"
      }else{
        this.param.uploadType = _.clone(this.uploadType)
      }
    },
    uploadClick(){
      if(this.uploadType=='gallery') return this.openGallery()
      return this.inputFile.click()
    },
    openGallery(){
      global.FileManager.open(files=>{
        if(files.length){
          var arrayValue = []
          files.forEach(v=>{
            arrayValue.push(v.pathfile)
          })
          this.$emit('input', arrayValue)
        }else{
          this.$emit('input', files.pathfile)
        }
        this.$emit('response', this)
      }, _.assign({type:this.type}, this.galleryParams))
    },
    removeUpload(){
      this.$emit('input', "")
    },
    onUpload(e){
      this.fileData = $(this.inputFile).prop('files')[0]
      this.conf.fileType = this.fileData.type
      this.fileType = this.fileData.name.substr(this.fileData.name.lastIndexOf('.') + 1).toLowerCase()
      if (this.conf.rule_size){
        var reader = new FileReader()
        reader.onload = () => {
          var img = new Image
          img.onload = () => {
            this.img = img
            this.uploadProcess(e)
          }
          img.src = reader.result
        }
        reader.readAsDataURL(this.fileData)
      }else{
        this.uploadProcess(e)
      }
    },
    uploadProcess(){
      // Validation
      this.error = false
      if (this.conf['rule_type'].indexOf(this.fileType) == -1) {
        this.error = 'File type must be ' + this.conf['rule_type'] + ' type.'
      }
      if (this.fileData.size > this.$root.app.uploader['max_image_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_image_size'].bytesToSize()
      }
      if(this.$root.app.uploader[this.type]['max_image_size']){
        if(this.fileData.size > this.$root.app.uploader[this.type]['max_image_size']){
          this.error = 'Max file size is '+this.$root.app.uploader[this.type]['max_image_size'].bytesToSize()
        }
      }
      // if (this.conf.rule_size) if(this.img.naturalWidth<this.conf.rule_size[0]||this.img.naturalHeight<this.conf.rule_size[1]){
      //   this.error = 'Minimum image size is '+this.conf.rule_size[0]+"x"+this.conf.rule_size[1]
      // }
      if (this.error){
        return alert(this.error)
      }

      // Automatic upload if not image
      if(("jpg,jpeg,png").indexOf(this.fileType) < 0) this.param.uploadType = "upload"

      // Quick Image Upload Filter
      if(this.param.uploadType != "upload"){
        let reader = new FileReader()
        reader.onload = e=>{
          this.imageFilter(e.target.result, this.fileData.name)
        }
        reader.readAsDataURL(this.fileData)
        return 
      }

      let formData = new FormData()
      formData.append(this.type=="editor" ? 'upload' : 'file', this.fileData)
      this.param.pageType = this.type
      $.each(this.param, (k, v) => {
        formData.append(k, v)
      })
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      this.uploading = true
      $.ajax({
        url: this.apiUrl + "/api/app/ajax/upload?"+Gen.objectToQuery(query),
        headers:Gen.apiHeader(),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        xhr: ()=>{
          let xhr = new window.XMLHttpRequest()
          let ajax_progress = $('<p style="margin-bottom:0px;" class="label_progress"></p><div class="progress" style="margin:0px 0px 0px;height:20px;"><div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style="width:3%"></div></div>')
          $(this.$el).find(".ajax_progress").html(ajax_progress)
          //Upload progress
          xhr.upload.addEventListener("progress", evt=>{
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total
              let label_process = "File Upload: "+(evt.loaded/1000)+  "Kb / " + (evt.total/1000)+"Kb."
              // Do something with upload progress
              ajax_progress.find('.progress-bar').width((percentComplete*100)+"%")
              $('.label_progress').text(label_process)
              if(percentComplete == 1){
                setTimeout(()=>{ ajax_progress.fadeOut(500) }, 600)
                setTimeout(()=>{ $(this.$el).find(".ajax_progress").html("") }, 1100)
              }
            }
          }, false)
          return xhr
        }
      }).done(resp => {
        this.uploading = false
        if (this.param.uploadType == "upload") {
          this.$emit('input', resp.pathfile)
          this.$emit('resp', resp)
          this.$emit('response', this)
          return
        }
        this.imageFilter(resp.targetfile, resp.filename)
      })
    },

    imageFilter(img, filename){
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      global.ImageFilter.open(img, data => {
        var form_data = new FormData()
        let date = new Date()
        form_data.append("file", data)
        form_data.append("path", this.type)
        form_data.append("image_name", filename)
        form_data.append("timestamp", Date.parse(date))
        this.$emit("beforeDone", this)
        // for(let i = 0; i < 2; i++){
        let url = this.apiUrl + "/api/app/ajax/upload_filter?"+Gen.objectToQuery(query)
        $.ajax({
          url: url,
          headers:Gen.apiHeader(),
          type: "POST",
          data: form_data,
          enctype: 'multipart/form-data',
          processData: false,  // tell jQuery not to process the data
          contentType: false,
          success: resp => {
            if(resp.type == 'webp'){
              this.$emit('input', resp.pathfile)
              this.$emit('response', this)
              // upload file ori
              this.postOri(form_data)
              this.imgFile=resp.targetfile
              if($("div").hasClass("avatar_prof")){
                $('.avatar_prof').html('<img src="'+resp.targetfile+'" alt="user">')
              }

              if(this.isMultiple){
                this.imgFileMultiple.push(resp.pathfile)

                this.$emit('input', this.imgFileMultiple)
              }
            }
          }
        })
        // }
      }, this.conf)
    },
    // upload file ori
    postOri(form_data){
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      let url = this.apiUrl + "/api/app/ajax/upload_filter_ori?"+Gen.objectToQuery(query)
      $.ajax({
        url: url,
        headers:Gen.apiHeader(),
        type: "POST",
        data: form_data,
        enctype: 'multipart/form-data',
        processData: false,  // tell jQuery not to process the data
        contentType: false,
      })
    },
    removeImage(url){
      this.imgFileMultiple = this.imgFileMultiple.filter(v => v != url)

      this.$emit('input', this.imgFileMultiple)
    }

  },
  watch:{
    type(){
      setTimeout(()=>{ this.init() },300)
    },
    '$root.app.uploader'(){
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped> 
.image-preview{ padding: 8px; background-color: #e3e3e3; margin-bottom: 8px;} .custom-file-uploader, .upload-import{ position: relative; .image-preview{ position: relative; .remove{ position: absolute; display: block; background: #ff6767; width: 18px; height: 18px; color: #fff; padding: 0px 5px; line-height: 15px; border-radius: 50%; top: -5px; right: 5px; } } } .ajax_progress{ height: 20px; right: 0; width: 40%; top: 0; display: inline-grid; position: absolute; } .upload-btn{ position: relative; input[type=file]{ position: absolute; top: 0px; left: 0px; opacity: 0; width: 100%; height: 100%; } } .upload_wrapper_pict{ vertical-align: middle; } .upload_wrapper_pict.upload_w100,.upload_wrapper_pict.upload_w100 .file-upload_pict{ display:block; } label{display: block;} .custom-file-uploader{ &-preview{ background-size: cover; background-position: center; background-repeat: no-repeat; position: relative; padding-top: 56.25%; background-color: #777; cursor: pointer; overflow: hidden; &__overlay{ display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center; position: absolute; top: 8px; left: 8px; right: 8px; bottom: 8px; background-color: rgba(255,255,255,.9); opacity: 0; z-index: -9; transition: .5s; transform: scale(1.5); } &:hover .custom-file-uploader-preview__overlay{ opacity: 1; z-index: 9; transform: scale(1); } } &-alert{ &__requirement{ font-size: 12px; font-weight: 500; display: block; } } &-img{ display: block; margin-left: auto; margin-right: auto; &.mw-150{ max-width: 150px; } } } .btn .feather{ vertical-align: text-bottom; }

  .file-uploader-media__preview{
    width: 100%;
    padding-top: 56.25%;
    display: block;
    background-position: center;
    background-size: cover;
    position: relative;
    .preview-indicator{
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255, .5);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .25s;
      i{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        line-height: 32px;
        background-color: blue;
        color: white;
        text-align: center;
      }
    }
    .remove-image {
      position: absolute;
      top: -1em;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: #d73c3c;
      border-radius: 50%;
      z-index: 10;

      i {        
        line-height: 32px;
        color: white;
        text-align: center;
      }
    }
    &:hover .preview-indicator{
      opacity: 1;
    }
  }
  .media-full-preview{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .5);
    padding: 24px;
    text-align: center;
    img{
      max-height: 100%
    }
    .preview__close{
      color: #fff;
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 9;
      font-size: 24px;
      opacity: .8;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &:hover{
        opacity: 1;
      }
    }
  }
</style>

<template>
<div v-if="conf">
  <div  
    v-if="uploadType=='import'"
  >
    <div class="fileSelector mb-1">
      <div class="text-center upload-import">
        <div 
          v-if="value" 
          class="fileSelectorIconUpload image-preview bg-success"
        >
          <feather-icon icon="FileIcon"
            class="my-50 text-white"
            size="36"
          />
          <a href="javascript:;" @click="removeUpload" class="remove" v-if="value&&!readonly">x</a>
        </div>
        <span v-else class="d-block">No File Chosen</span>
      </div>
      <div class="fileSelectorUploader mt-1">
        <div class="text-center">
          <label 
            @click="uploadClick"
            for="fsUploader"
            class="btn btn-primary"
          >
            <span>{{ `${value ? 'Update' : 'Choose'} File` }}</span>
          </label>
        </div>
      </div>
    </div>
    <b-alert variant="info" show >
      <div class="alert-body text-center">
        <span
          class="d-block"
        >Format : <span class="text-uppercase">{{conf.rule_type}}</span> (Max. Size: {{(conf.max_image_size||$root.app.uploader["max_image_size"]).bytesToSize()}})</span>
      </div>
    </b-alert>
  </div>

  <div 
    v-else
    class="file-uploader-cta"
  >
    <slot name="preview">
      <div v-show="(!noPreview && value && !isMultiple) || (!noPreview && lengthArr(value) && isMultiple)" class="image-preview">
        <template v-if="!isMultiple">
          <a
            href="javascript:;"
            v-if="usePreview"
            class="file-uploader-media__preview"
            :style="
            value ? 'background-image: url('+uploader(value)+')' : 'background-image: url(/assets/images/user-placeholder.svg)'
            "
            @click="fullPreviewOpen = true"
          >
            <div class="preview-indicator" @click="removeImage(value)">
              <i class="ti-eye"></i>
            </div>
          </a>
          <b-img
            fluid
            v-else
            :src="value ? uploader(value) : '/assets/images/user-placeholder.svg'"
            :class="{'custom-file-uploader-img':1, 'rounded-circle mw-150':type=='user'}"
          />
        </template>

        <template v-if="isMultiple">
          <template v-if="lengthArr(imgFileMultiple)">
            <span v-for="(value, k) in imgFileMultiple" :key="k">
              <a
                href="javascript:;"
                v-if="usePreview"
                class="file-uploader-media__preview"
                :class="k != 0 ? 'mt-3' : ''"
                :style="
                value ? 'background-image: url('+uploader(value)+')' : 'background-image: url(/assets/images/user-placeholder.svg)'
                "                
              >
                <div class="preview-indicator" @click="fullPreviewOpen = true">
                  <i class="ti-eye"></i>
                </div>

                <div class="remove-image" @click="removeImage(value)">
                  <i class="ti-close"></i>
                </div>
              </a>
              <b-img
                fluid
                v-else
                :src="value ? uploader(value) : '/assets/images/user-placeholder.svg'"
                :class="{'custom-file-uploader-img':1, 'rounded-circle mw-150':type=='user'}"
              />
            </span>
          </template>
        </template>
        <!-- <ImagePreview class="mr10" :src="uploader(value)" v-if="value"></ImagePreview>
        <a href="javascript:;" @click="removeUpload" class="remove" v-if="value&&!readonly">x</a> -->
      </div>
      <!-- <div v-if="!value" class="d-flex justify-content-center">
        <div class="file-uploader-media"><div class="text-center"><strong>No Image Available</strong></div></div>
      </div> -->
    </slot>
    <slot name="btn" v-if="!readonly">
      <div href="javascript:;" :class="(btnClass||'')" @click="uploadClick">
        <label class="btn btn-uploader btn-block">
          <slot 
            v-if="!uploading" 
            name="label" 
            :uploadText="uploadText">
            <i :class="conf.rule_type == 'pdf' ? 'icon-docs':'icon-picture'" />
            <span class="align-middle">{{uploadText}} {{label||'File'}}</span>
          </slot>
          <span v-if="uploading">
            <i :class="conf.rule_type == 'pdf' ? 'icon-docs':'icon-picture'" />
            <span class="align-middle">Uploading...</span>
          </span>
        </label>
      </div>
    </slot>
    <div v-if="!noLoading" class="ajax_progress" style=""> </div>
    <slot name="help">
      <b-alert
        v-if="!noHelp&&!readonly"
        variant="info"
        show
        class="mb-0 custom-file-uploader-alert"
      >
        <div class="alert-body text-center">
          <span v-if="conf.imgsize_recommend" class="custom-file-uploader-alert__requirement">
            Required Image Dimension : {{conf.imgsize_recommend}}
          </span>
          <span
            class="custom-file-uploader-alert__requirement"
          >Format {{conf.rule_type}}: <span class="text-uppercase">{{conf.rule_type}}</span> (Max. Size: {{(conf.max_image_size||$root.app.uploader["max_image_size"]).bytesToSize()}})</span>
        </div>
      </b-alert>
    </slot>
  </div>
  <div
    class="media-full-preview"
    v-if="usePreview"
    v-show="fullPreviewOpen"
    @click="fullPreviewOpen = false"
  >
    <b-img :src="value ? uploader(value) : '/assets/images/user-placeholder.svg'" @click.stop />
    <a href="javascript:;" class="preview__close"><i class="fas fa-times"></i></a>
  </div>
</div>
</template>