import { render, staticRenderFns } from "./Uploader.vue?vue&type=template&id=3a98c6cf&scoped=true&"
import script from "./Uploader.vue?vue&type=script&lang=js&"
export * from "./Uploader.vue?vue&type=script&lang=js&"
import style0 from "./Uploader.vue?vue&type=style&index=0&id=3a98c6cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a98c6cf",
  null
  
)

export default component.exports